
import Header from '@/components/header/index.vue'
import { times } from 'lodash'
export default {
    name: 'information',
    components:{
        Header,
      
    },
    mounted () {
    },
    created(){
        this.query=this.$route.query

    },
    methods: {
        
    },

    data(){
        return{
            query:{},
           
            

        }
        
    }
    
}